import { privateAxios } from "../../utils/axios";

// Login user
const dashboard = async () => {
  const response = await privateAxios.get("/lista/dashboard");
  return response.data;
};

const dashboardService = {
  dashboard,
};

export default dashboardService;
