import { paxios } from "../../utils/axios";

export const role = async () => {
  const url = "/user/role";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};

export const plan = async () => {
  const url = "/user/plan";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};
