 
import React, { useEffect } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

const CommandControl = ({ setIsRutasChecked, setShowRoutes, id }) => {
   
  const map = useMap();

  useEffect(() => {
    // Create a new Leaflet control
    const command = L.control({ position: "topleft" });

    // Function to handle checkbox change
    const handleCheckboxChange = (event) => {
      if (event.target.checked) {
        setIsRutasChecked(true);
        setShowRoutes(true);
      } else {
        setIsRutasChecked(false);
        setShowRoutes(false);
      }
    };

    // Function to create the checkbox control
    const createCheckboxControl = () => {

      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.checked = true;
      checkbox.addEventListener("change", handleCheckboxChange);

      checkbox.style.marginRight = "4px";

      // Create a label for the checkbox
      const label = document.createElement("label");
      label.style.display = "flex";
      label.style.alignItems = "center";

      label.appendChild(checkbox);
      label.appendChild(document.createTextNode("Rutas"));

      // Create a div for the checkbox
      const checkboxContainer = document.createElement("div");
      checkboxContainer.style.marginBottom = "5px";
      checkboxContainer.className = `leaflet-control-layers-selector `;
      checkboxContainer.appendChild(label);

      //   layersListContainer.appendChild(checkboxContainer);

      return checkboxContainer;
    };

    command.onAdd = function () {
      const div = L.DomUtil.create("div");

      // Find the leaflet-control-layers-list container
      const layersListContainer = document.querySelector(
        ".leaflet-control-layers-list"
      );

      if (layersListContainer) {
        // Add the custom checkbox control to the leaflet-control-layers-list
        const checkboxControl = createCheckboxControl();
        layersListContainer.appendChild(checkboxControl);
      }

      return div;
    };

    command.addTo(map);

    // Cleanup function
    return () => {
      // Remove the control from the map when the component is unmounted
      map.removeControl(command);
    };
  }, [map]);

  return null; // React components must return something
};

export default CommandControl;
