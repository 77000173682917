import React, { useEffect, useState } from "react";
import "./SearchField.scss";
import Autocomplete from "react-google-autocomplete";
import "leaflet.fullscreen/Control.FullScreen.css";
import "leaflet.fullscreen/Control.FullScreen.js";
import L from "leaflet";
import { useMap, useMapEvents } from "react-leaflet";
import Search from "../../assets/icons/Search.png";
import { useLocation } from "react-router-dom";
import { GOOGLE_API } from "../../utils";
import { AiFillDelete } from "react-icons/ai";
import deleteIcon from "../../assets/icons/deleteIcon.png";

const SearchField = ({ setSearchFieldVisible, searchFieldVisible }) => {
  const location = useLocation();
  const { pathname } = location;

  let apiKey = GOOGLE_API;
  const map = useMap();
  let marker;

  const getReverseGeocode = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch address information");
      }

      const data = await response.json();

      if (data.results && data.results.length > 0) {
        const address = data.results[0].formatted_address;

        return address;
      } else {
        throw new Error("No address found for the given coordinates");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const addMarker = async (lat, lng) => {
    let data = await getReverseGeocode(lat, lng);

    const markerIcon = new L.Icon({
      iconSize: [40, 40],
      iconUrl: Search,
    });

    marker = L.marker([lat, lng], { icon: markerIcon }).addTo(map);

    var customPopup =
      `<img id='deleteButton' style="cursor:pointer"  width=25 src="${deleteIcon}" />` +
      `<div style="margin-top:0.3rem">${data}</div>`;

    // Specify popup options
    var customOptions = {
      // maxWidth: "400",
      // width: "200",
      className: "popupCustom",
    };

    marker
      .bindPopup(customPopup, customOptions)
      .openPopup()
      .on("remove", (e) => {
        e.target._closeButton.href = this.router.url;
      });

    marker.on("popupclose", (e) => {
      // event.preventDefault();
    });
    
   

    document
      .getElementById("deleteButton")
      .addEventListener("click", function () {
        map.removeLayer(marker);
      });
      
     
     
      
    const markerLatLng = marker.getLatLng();

    const bounds = L.latLngBounds(markerLatLng, markerLatLng);
    map.fitBounds(bounds);

    pathname == "/mapa" && setSearchFieldVisible(false);
  };

  function dmsToDecimal(degrees, minutes, seconds, direction) {
    let decimalDegrees =
      parseFloat(degrees) +
      parseFloat(minutes) / 60 +
      parseFloat(seconds) / 3600;

    if (direction === "S" || direction === "W") {
      decimalDegrees = -decimalDegrees;
    }

    return decimalDegrees;
  }

  const parseCoordinates = (input) => {
    let name = input?.name;

    const dmsRegex =
      /(\d+)°(\d+)'([\d.]+)"([NSWE]) (\d+)°(\d+)'([\d.]+)"([NSWE])/;
    const dmsMatch = name.match(dmsRegex);

    if (dmsMatch) {
      const latDegrees = parseFloat(dmsMatch[1]);
      const latMinutes = parseFloat(dmsMatch[2]);
      const latSeconds = parseFloat(dmsMatch[3]);
      const latDirection = dmsMatch[4];

      const lngDegrees = parseFloat(dmsMatch[5]);
      const lngMinutes = parseFloat(dmsMatch[6]);
      const lngSeconds = parseFloat(dmsMatch[7]);
      const lngDirection = dmsMatch[8];

      const lat = dmsToDecimal(
        latDegrees,
        latMinutes,
        latSeconds,
        latDirection
      );
      const lng = dmsToDecimal(
        lngDegrees,
        lngMinutes,
        lngSeconds,
        lngDirection
      );

      return { lat, lng };
    } else {
      let __lat = name.split(",")[0];
      let __lng = name.split(",")[1];

      const lat = parseFloat(__lat);
      const lng = parseFloat(__lng);

      return { lat, lng };
    }
  };

  return (
    <div
      className={
        pathname == "/fichas"
          ? "leaflet-record-control-search"
          : "leaflet-control-search"
      }
    >
      {searchFieldVisible && (
        <Autocomplete
          apiKey={apiKey}
          placeholder="Buscar lugares o coordenadas"
          onPlaceSelected={(data) => {
            if (data.geometry && data.geometry.location) {
              // Place search
              const latitude = data.geometry.location.lat();
              const longitude = data.geometry.location.lng();
              if (!isNaN(latitude) && !isNaN(longitude)) {
                  addMarker(latitude, longitude, data?.formatted_address);
              }
            } else {
              const coordinates = parseCoordinates(data);

              addMarker(coordinates.lat, coordinates.lng);
              if (!isNaN(coordinates.lat) && !isNaN(coordinates.lng)) {
              } else {
                  console.error("Invalid coordinates input");
              }
            }
          }}
          types={["geocode", "establishment"]}
        />
      )}

    </div>
  );
};

export default SearchField;
