/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import Avvvatars from "avvvatars-react";
import { useDispatch } from "react-redux";
import { logout, reset } from "../../../features/auth/authSlice";

// Axios
import { role } from "../axios";

const User = ({ className }) => {
  const [visible, setVisible] = useState(false);
  const [user] = useState(JSON.parse(localStorage.getItem("user")) || {});
  const [navigation, setnavigation] = useState([]);

   
  // const { user } = useSelector((state) => state.auth);

  var menuPefil = {
    menu: [
      {
        title: "Perfil de Usuario",
        icon: "person",
        url: `/admin/user/${user._id}`,
      },
    ],
  };

  var menuMejoraPlan = {
    menu: [
      {
        title: "Mejora tu Plan",
        icon: "leaderboard",
        color: true,
        url: "/admin/planes/list",
      },
    ],
  };

  var menuLogout = {
    menu: [
      {
        title: "Cerrar Sesión",
        icon: "close-circle",
        idTitle: "session",
      },
    ],
  };

  useEffect(() => {
    loadRoleInfo();
  }, []);

  const loadRoleInfo = async () => {
    let apiRoleReturns = await role();
    if (apiRoleReturns.data === "ROOT") {
      setnavigation((prev) => [...prev, menuPefil, menuMejoraPlan, menuLogout]);
    }
    if (apiRoleReturns.data === "ADMIN") {
      setnavigation((prev) => [...prev, menuMejoraPlan, menuLogout]);
    }
    if (apiRoleReturns.data === "SECUNDARIO") {
      setnavigation((prev) => [...prev, menuLogout]);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    navigate("/");
    window.location.reload();
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <button
          className={styles.head}
          onClick={() => setVisible(!visible)}
          data-aos="fade-down"
          data-aos-offset="-100"
          data-aos-delay="200"
          data-aos-easing="ease-in-out"
        >
          {/* <img src="/images/content/avatar.jpg" alt="Avatar" /> */}
          <Avvvatars
            // style="shape"
            displayValue={user ? user.email : ""}
            size={48}
            // shadow={true}
            // border={true}
            // borderSize={20}
          />
        </button>
        <div className={styles.body}>
          {navigation &&
            navigation.map((item, index) => (
              <div className={styles.menu} key={index}>
                {item.menu.map((x, index) =>
                  x.url ? (
                    <NavLink
                      className={({ isActive }) =>
                        isActive
                          ? cn(styles.item, styles.active)
                          : cn(styles.item, { [styles.color]: x.color })
                      }
                      to={x.url}
                      onClick={() => setVisible(false)}
                      key={index}
                    >
                      {x.icon && <Icon name={x.icon} size="24" />}
                      {x.title}
                    </NavLink>
                  ) : (
                    <button
                      className={styles.item}
                      onClick={
                        x.idTitle === "session"
                          ? () => [setVisible(false), onLogout()]
                          : () => setVisible(false)
                      }
                      key={index}
                    >
                      {x.icon && <Icon name={x.icon} size="24" />}
                      {x.title}
                    </button>
                  )
                )}
              </div>
            ))}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
