import React from "react";
import { CirclePicker } from "react-color";
import styles from "./Info.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TextInput from "../../../../components/TextInput";
import {
  renderSwitchIcons,
  renderSwitchColor,
  nameIcons,
  nameIcons2,
  colors,
} from "../../../../const/ConstMapa";

const InfoCrearDraw = ({
  drawCreatedForm,
  setDrawCreatedForm,
  initialForm,
  handlerSelectMarker,
  handlerSelectColorPolyline,
  markerLeaflet,
}) => {
  // Para formulario
  const onChangeHandlerInput = (e) => {
    const { name, value } = e.target;
    setDrawCreatedForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    initialForm[name] = value;
    if (name === "colorRuta") handlerSelectColorPolyline(value);
  };

  // Para color titulo
  const onChangeHandlerColorTitulo = (color) => {
    setDrawCreatedForm((prevState) => ({
      ...prevState,
      colorTitulo: renderSwitchColor(color.hex),
      colorTituloHex: color.hex,
    }));
    initialForm.colorTitulo = renderSwitchColor(color.hex);
    initialForm.colorTituloHex = color.hex;
  };

  // Para color ruta popup
  const onChangeHandlercolorRutaPopup = (color) => {
    setDrawCreatedForm((prevState) => ({
      ...prevState,
      colorRutaPopup: renderSwitchColor(color.hex),
      colorRutaPopupHex: color.hex,
    }));
    initialForm.colorRutaPopup = renderSwitchColor(color.hex);
    initialForm.colorRutaPopupHex = color.hex;
  };

  return (
    <div className={styles.success}>
      <h2>Opciones</h2>
      <br />
      <div className={styles.row}>
        <div className={styles.col}>
          <TextInput
            className={styles.field}
            label="Título Marcador"
            name="tituloMarcador"
            type="text"
            placeholder="Título Marcador"
            tooltip="Más de 2 caracteres"
            required
            maxLength="20"
            onChange={onChangeHandlerInput}
            value={drawCreatedForm.tituloMarcador}
          />
          <TextInput
            className={styles.field}
            label="Descripción Marcador"
            name="descripcionMarcador"
            type="text"
            placeholder="Descripción Marcador"
            tooltip="Más de 2 caracteres"
            required
            onChange={onChangeHandlerInput}
            value={drawCreatedForm.descripcionMarcador}
          />
          <h4 className={styles.field}>Color de Título</h4>
          <div className={styles.field}>
            <CirclePicker
              color={drawCreatedForm.colorTituloHex}
              onChange={(e) => onChangeHandlerColorTitulo(e)}
              circleSpacing={7}
              width={"100%"}
              styles={{ maxWidth: "500px" }}
              colors={colors}
            />
          </div>
          <h4 className={styles.field}>Iconos</h4>
          <div className={styles.field}>
            {nameIcons.map((item, index) => {
              return (
                <button
                  className={cn(
                    "button-square-stroke button-small",
                    styles.iconbutton,
                    markerLeaflet.nameIcon.includes(item)
                      ? styles.selectbutton
                      : null
                  )}
                  onClick={() => handlerSelectMarker(item)}
                  key={index}
                >
                  <img src={renderSwitchIcons(item)} height={"26"} alt={item} />
                </button>
              );
            })}
          </div>
          <div className={styles.iconcol}>
            {nameIcons2.map((item, index) => {
              return (
                <div className={styles.iconsubcol} key={index}>
                  <h4 className={styles.field}>{item.name}</h4>
                  <button
                    className={cn(
                      "button-square-stroke button-small",
                      styles.iconbutton,
                      markerLeaflet.nameIcon.includes(item.icon)
                        ? styles.selectbutton
                        : null
                    )}
                    onClick={() => handlerSelectMarker(item.icon)}
                  >
                    <img
                      src={renderSwitchIcons(item.icon)}
                      height={"26"}
                      alt={item}
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.col}>
          <TextInput
            className={styles.field}
            label="Título Ruta"
            name="tituloRuta"
            type="text"
            placeholder="Título Ruta"
            tooltip="Más de 2 caracteres"
            required
            maxLength="20"
            onChange={onChangeHandlerInput}
            value={drawCreatedForm.tituloRuta}
          />
          <TextInput
            className={styles.field}
            label="Descripción de Ruta"
            name="descripcionRuta"
            type="text"
            placeholder="Descripción de Ruta"
            tooltip="Más de 2 caracteres"
            required
            onChange={onChangeHandlerInput}
            value={drawCreatedForm.descripcionRuta}
          />
          <h4 className={styles.field}>Color de Popup</h4>
          <div className={styles.field}>
            <CirclePicker
              color={drawCreatedForm.colorRutaPopupHex}
              onChange={(e) => onChangeHandlercolorRutaPopup(e)}
              circleSpacing={7}
              width={"100%"}
              styles={{ maxWidth: "500px" }}
              colors={colors}
            />
          </div>
          <TextInput
            className={styles.field}
            label="Color de Ruta"
            name="colorRuta"
            type="color"
            onChange={onChangeHandlerInput}
            value={drawCreatedForm.colorRuta}
          />
        </div>
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default InfoCrearDraw;
