const optionsSelectTipoRol = ["ADMIN", "SECUNDARIO"];
const optionsSelectEstado = ["ACT", "DEACT", "PENDING"];
const optionsSelectEstadoSec = ["ACT", "DEACT"];

//
const opcionPermissionCreateUser = [
  { type: "CREATE-MAP", name: "Crear Ficha" },
  { type: "EDIT-MAP", name: "Editar Ficha" },
  { type: "DELETE-MAP", name: "Eliminar Ficha" },
];
//

//
var datasDrawsSave = [];

const setDatasDrawsSave = (value) => {
  datasDrawsSave = value;
};

const getDatasDrawsSave = () => {
  return datasDrawsSave;
};
//

//
var darkMode = false;

const setDarkMode = (value) => {
  darkMode = value;
};

const getDarkMode = () => {
  return darkMode;
};
//

export {
  optionsSelectTipoRol,
  optionsSelectEstado,
  optionsSelectEstadoSec,
  opcionPermissionCreateUser,
  setDatasDrawsSave,
  getDatasDrawsSave,
  setDarkMode,
  getDarkMode,
};
