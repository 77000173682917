import React from "react";
import styles from "./Info.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TextInput from "../../../../components/TextInput";

const InfoSave = ({
  datasDrawsTotal,
  setTituloFicha,
  tituloFicha,
  onHandlerSave,
}) => {
  // Para formulario
  const onChangeHandlerInput = (e) => {
    const { value } = e.target;
    setTituloFicha(value);
  };

  return (
    <div className={styles.success}>
      <h2>Actualizar</h2>
      <br />
      <div className={styles.row}>
        <div className={styles.col}>
          <form onSubmit={onHandlerSave}>
            <TextInput
              className={styles.field}
              label="Título de Ficha"
              name="tituloMarcador"
              type="text"
              placeholder="Nombre | Colonia | País | Ciudad | etc..."
              tooltip="Más de 2 caracteres"
              required
              onChange={onChangeHandlerInput}
              value={tituloFicha}
            />
            <div className={styles.subcol}>
              <TextInput
                className={styles.field}
                label="Total de Marcadores Creados"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={datasDrawsTotal.marcadoresCount}
              />
              <TextInput
                className={styles.field}
                label="Total de Rutas Creados"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={datasDrawsTotal.rutasCount}
              />
            </div>
            <div className={styles.subcol}>
              <TextInput
                className={styles.field}
                label="Total de Mangas Creadas"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={datasDrawsTotal.mangasCount}
              />
              <TextInput
                className={styles.field}
                label="Total de Reservas Creadas"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={datasDrawsTotal.reservasCount}
              />
            </div>
            <div className={styles.subcol}>
              <TextInput
                className={styles.field}
                label="NAP_I"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={datasDrawsTotal.nap_iCount}
              />
              <TextInput
                className={styles.field}
                label="NAP_II"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={datasDrawsTotal.nap_iiCount}
              />
            </div>
            <div className={styles.subcol}>
              <TextInput
                className={styles.field}
                label="ONT"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={datasDrawsTotal.ontCount}
              />
              <TextInput
                className={styles.field}
                label="Suma Total de Rutas"
                type="text"
                placeholder="0"
                tooltip="Más de 1 caracter"
                required
                maxLength="20"
                value={`${datasDrawsTotal.sumaDistanceRutas} metros`}
              />
            </div>

            <div className={styles.btns}>
              <button className={cn("button", styles.button)} type="submit">
                Actualizar Ficha
              </button>
            </div>
          </form>
        </div>
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default InfoSave;
