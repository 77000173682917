import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import User from "./User";

import { role, plan } from "./axios";
import { status } from "../../axios";

const Header = ({ onOpen }) => {
  const [dataPlan, setDataPlan] = useState("");
  const [dataRole, setDataRole] = useState("");
  const [dataNDaysExpire, setDataNDaysExpire] = useState("");
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    loadInfo();
  }, []);

  const loadInfo = async () => {
    try {
      let apiReturnStatus = await status();
      if (apiReturnStatus && apiReturnStatus.data === "PENDING")
        setIsPending(true);
      let apiRoleReturns = await role();
      if (apiRoleReturns.data === "ADMIN") {
        let apiPlanReturns = await plan();
        let dataUser = JSON.parse(localStorage.getItem("user")) || {};
        setDataPlan(apiPlanReturns.data);
        setDataRole(apiRoleReturns.data);
        setDataNDaysExpire(
          Math.floor(
            (dataUser.info.dateLimit - new Date().getTime()) /
              (1000 * 3600 * 24)
          )
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = () => {
    onOpen();
  };

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>

      <div className={styles.control}>
        {dataRole !== "ROOT" ? (
          dataPlan ? (
            <>
              <button
                className={cn(
                  "button-stroke",
                  styles.buttonmobil,
                  styles.buttoncustom
                )}
              >
                <Icon name="diamond" size="24" />
                <span>Paquete {dataPlan}</span>
              </button>
              <button
                className={cn(
                  "button-stroke",
                  styles.buttonmobil,
                  styles.buttoncustom
                )}
              >
                <span>-{dataNDaysExpire} DÍAS</span>
              </button>
            </>
          ) : null
        ) : null}
        {!isPending && (
          <Link className={cn("button", styles.button)} to="/mapa">
            <Icon name="add" size="24" />
            <span>Crear</span>
          </Link>
        )}
        <User className={styles.user} />
      </div>
    </header>
  );
};

export default Header;
