/* eslint-disable array-callback-return */
import React from "react";
import cn from "classnames";
import styles from "./PreviewCard.module.sass";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import Icon from "../../../../components/Icon";

const PreviewCard = ({
  formDataPlan,
  setFormDataPlan,
  selectColor,
  selectRibbon,
}) => {
  const handlerColor = (value) => {
    switch (value) {
      case "💚 Verde":
        return "title-green";
      case "💙 Azul":
        return "title-blue";
      case "💛 Amarillo":
        return "title-yellow";
      case "🧡 Rojo":
        return "title-red";
      default:
        return "";
    }
  };

  const handlerRibbon = (value) => {
    switch (value) {
      case "On":
        return true;
      case "Off":
        return false;
      default:
        return false;
    }
  };

  const deleteOptionList = (indexSelect) => {
    let listOption = formDataPlan.listopciones;
    formDataPlan.listopciones.map((item, index) => {
      if (index === indexSelect) {
        listOption.splice(index, 1);
      }
    });
    setFormDataPlan((prev) => ({ ...prev, listopciones: listOption }));
  };

  return (
    <div className={cn(styles.preview)}>
      <div className={cn(styles.packages)}>
        <div className={styles.list}>
          <div className={styles.package}>
            <div className={styles.top}>
              {handlerRibbon(selectRibbon) && (
                <div className={cn(handlerColor(selectColor), styles.title)}>
                  {formDataPlan.ribbon}
                </div>
              )}
            </div>
            <div className={styles.line}>
              <div className={cn("h2", styles.percent)}>
                ${JSON.parse(formDataPlan.precio).toFixed(2)}
              </div>
              <div className={styles.note}>{formDataPlan.titulo}</div>
            </div>
            <ul className={styles.group}>
              {formDataPlan.listopciones.map((item, index) =>
                item.status === "✅" ? (
                  <div className={styles.containerlist}>
                    <button
                      className={cn("button-stroke-red", styles.buttontrash)}
                      onClick={() => deleteOptionList(index)}
                    >
                      <Icon name="trash" size="20" />
                    </button>
                    <li className={styles.item} key={index}>
                      {item.lista}
                    </li>
                  </div>
                ) : (
                  <div className={styles.containerlist}>
                    <button
                      className={cn("button-stroke-red", styles.buttontrash)}
                      onClick={() => deleteOptionList(index)}
                    >
                      <Icon name="trash" size="20" />
                    </button>
                    <li className={styles.itemx} key={index}>
                      {item.lista}
                    </li>
                  </div>
                )
              )}
            </ul>
            <br />
            <div className={styles.line}>
              <div className={styles.rowplan}>
                <div className={styles.columnplan}>
                  <h4>Rutas</h4>
                  <div className={styles.countplan}>{formDataPlan.Rutas}</div>
                </div>
                <div className={styles.columnplan}>
                  <h4>Marcadores</h4>
                  <div className={styles.countplan}>
                    {formDataPlan.Marcadores}
                  </div>
                </div>
                <div className={styles.columnplan}>
                  <h4>Reservas</h4>
                  <div className={styles.countplan}>
                    {formDataPlan.Reservas}
                  </div>
                </div>
                <div className={styles.columnplan}>
                  <h4>Mangas</h4>
                  <div className={styles.countplan}>{formDataPlan.Mangas}</div>
                </div>
                <div className={styles.columnplan}>
                  <h4>NAP_I</h4>
                  <div className={styles.countplan}>{formDataPlan.NAP_I}</div>
                </div>
                <div className={styles.columnplan}>
                  <h4>NAP_II</h4>
                  <div className={styles.countplan}>{formDataPlan.NAP_II}</div>
                </div>
                <div className={styles.columnplan}>
                  <h4>ONTS</h4>
                  <div className={styles.countplan}>{formDataPlan.ONTS}</div>
                </div>
                <div className={styles.columnplan}>
                  <h4>Usuarios</h4>
                  <div className={styles.countplan}>
                    {formDataPlan.limiteUsers}
                  </div>
                </div>
                <div className={styles.columnplan}>
                  <h4>Días</h4>
                  <div className={styles.countplan}>{formDataPlan.Dias}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default PreviewCard;
