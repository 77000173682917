import React from "react";

import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

//private route  component handling its children v2
export const PrivateRoute = ({ children }) => {
  const { isSuccess } = useSelector((state) => state.auth);

  if (!isSuccess) return <Navigate to={"/login"} />;
  if (isSuccess) return children;
};

// google api key
export const GOOGLE_API = "AIzaSyD2r6Sj32chxJxKl0Cpi0hyFPdXEICKb2s";

export const CATEGORY_TYPES = [
  "Manga",
  "Reserva",
  "Nap_i",
  "Nap_ii",
  "Marcadores",
  "Ont",
  "Rutas",
];
