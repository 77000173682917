/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./CrearPlan.module.sass";
import { Navigate, useParams } from "react-router-dom";

// Components
import TooltipGlodal from "../../../components/TooltipGlodal";

// Axios
import { getOne, role } from "./axios";

import PreviewCard from "./PreviewCard";
import Form from "./Form";

const initialPlan = {
  idplan: "",
  ribbon: "",
  titulo: "",
  precio: 0,
  listopciones: [],
  opciones: "",
  Rutas: 0,
  Marcadores: 0,
  Reservas: 0,
  Mangas: 0,
  NAP_I: 0,
  NAP_II: 0,
  ONTS: 0,
  limiteUsers: 0,
  Dias: 0,
};

const ModificarPlan = () => {
  const { id } = useParams();

  const [formDataPlan, setFormDataPlan] = useState(initialPlan);
  const [selectIcon, setSelectIcon] = useState("✅");
  const [selectColor, setSelectColor] = useState("");
  const [selectRibbon, setSelectRibbon] = useState("");

  const [reload, setReload] = useState(false);

  const handlerColor = (value) => {
    switch (value) {
      case "success":
        return "💚 Verde";
      case "primary":
        return "💙 Azul";
      case "warning":
        return "💛 Amarillo";
      case "danger":
        return "🧡 Rojo";
      default:
        return "";
    }
  };

  const handlerRibbon = (value) => {
    switch (value) {
      case true:
        return "On";
      case false:
        return "Off";
      default:
        return "Off";
    }
  };

  useEffect(() => {
    loadPlanInfo();
  }, []);

  const loadPlanInfo = async () => {
    try {
      let apiRoleReturns = await role();

      if (apiRoleReturns.data === "ROOT") {
        let apiplanReturns = await getOne(id);
        if (apiplanReturns.data.status === "VALID") {
          setFormDataPlan((prev) => ({
            ...prev,
            idplan: apiplanReturns.data.data._id,
            ribbon: apiplanReturns.data.data.ribbon,
            titulo: apiplanReturns.data.data.titulo,
            precio: JSON.parse(apiplanReturns.data.data.precio),
            listopciones: apiplanReturns.data.data.listopciones,
            Rutas: apiplanReturns.data.data.Rutas,
            Marcadores: apiplanReturns.data.data.Marcadores,
            Reservas: apiplanReturns.data.data.Reservas,
            Mangas: apiplanReturns.data.data.Mangas,
            NAP_I: apiplanReturns.data.data.NAP_I,
            NAP_II: apiplanReturns.data.data.NAP_II,
            ONTS: apiplanReturns.data.data.ONTS,
            limiteUsers: apiplanReturns.data.data.limiteUsers,
            Dias: apiplanReturns.data.data.Dias,
          }));
          setSelectRibbon(handlerRibbon(apiplanReturns.data.data.switchribbon));
          setSelectColor(handlerColor(apiplanReturns.data.data.cardcolor));
        } else {
          setReload(true);
        }
      } else {
        setReload(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (reload) return <Navigate to={"/"} />;

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Form
            formDataPlan={formDataPlan}
            setFormDataPlan={setFormDataPlan}
            selectIcon={selectIcon}
            setSelectIcon={setSelectIcon}
            selectColor={selectColor}
            setSelectColor={setSelectColor}
            selectRibbon={selectRibbon}
            setSelectRibbon={setSelectRibbon}
          />
        </div>
        <div className={styles.col}>
          <PreviewCard
            formDataPlan={formDataPlan}
            setFormDataPlan={setFormDataPlan}
            selectColor={selectColor}
            selectRibbon={selectRibbon}
          />
        </div>
      </div>

      <TooltipGlodal />
    </>
  );
};

export default ModificarPlan;
