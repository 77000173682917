/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Image from "../Image";

// Axios
import { role, permissionSecundario } from "./axios";
import { status } from "../../axios";

const sidebarOptions = [
  {
    id: 1,
    title: "Inicio",
    icon: "home",
    url: "/",
  },
];

const Sidebar = ({ className, onClose }) => {
  const [visible, setVisible] = useState(false);
  const [navigation, setnavigation] = useState(sidebarOptions);

  var fichasOptions = {
    id: 2,
    title: "Fichas",
    icon: "list",
    url: "/fichas",
  };

  var mapaOptions = {
    id: 3,
    title: "Mapa",
    icon: "activity",
    url: "/mapa",
  };

  var usuariosOptions = {
    id: 4,
    title: "Usuarios",
    slug: "group-stroke",
    icon: "profile-circle",
    dropdown: [
      {
        title: "Crear Usuario",
        url: "/admin/user/crear",
      },
      {
        title: "Lista de Usuarios",
        url: "/admin/user/list",
      },
    ],
  };

  var pagosOptions = {
    id: 5,
    title: "Pagos",
    icon: "payment",
    dropdown: [],
  };

  var itemHistorialPagosOption = {
    title: "Historial de Pagos",
    url: "/admin/historial-pagos",
  };

  var itemAumentarPlanOption = {
    title: "Aumentar Plan",
    url: "/admin/planes/list",
  };

  var controlPlanesOptions = {
    id: 6,
    title: "Control de Planes",
    slug: "store",
    icon: "setting",
    dropdown: [
      {
        title: "Crear Plan",
        url: "/admin/planes/crear",
      },
      {
        title: "Modificar Planes",
        url: "/admin/planes/list",
      },
    ],
  };

  var correoOption = {
    id: 7,
    title: "Correo",
    icon: "mail",
    url: "/admin/email",
  };

  var backupOption = {
    id: 8,
    title: "Backup",
    icon: "copy",
    url: "/admin/backup",
  };

  useEffect(() => {
    loadRoleInfo();
  }, []);

  const loadRoleInfo = async () => {
    try {
      let apiReturnStatus = await status();
      let apiRoleReturns = await role();

      if (apiRoleReturns.data === "ROOT") {
        pagosOptions.dropdown.push(itemHistorialPagosOption);
        setnavigation((prev) => [
          ...prev,
          fichasOptions,
          usuariosOptions,
          controlPlanesOptions,
          correoOption,
          mapaOptions,
        ]);
      }
      if (apiReturnStatus.data !== "PENDING") {
        if (apiRoleReturns.data === "ADMIN") {
          pagosOptions.dropdown.push(itemAumentarPlanOption);
          setnavigation((prev) => [
            ...prev,
            fichasOptions,
            usuariosOptions,
            backupOption,
            mapaOptions,
          ]);
        }
        if (pagosOptions.dropdown.length) {
          setnavigation((prev) => [...prev, pagosOptions]);
        }
      }
      if (apiRoleReturns.data === "SECUNDARIO") {
        setnavigation((prev) => [...prev, fichasOptions]);
        let apiPermissionReturns = await permissionSecundario();
        if (apiPermissionReturns.data) {
          if (apiPermissionReturns.data.includes("CREATE-MAP"))
            setnavigation((prev) => [...prev, mapaOptions]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link
          className={styles.logo}
          to="/"
          onClick={onClose}
          data-aos="fade-down"
          data-aos-offset="-100"
          data-aos-delay="200"
          data-aos-easing="ease-in-out"
        >
          <Image
            className={styles.pic}
            src="/android-chrome-512x512.png"
            srcDark="/android-chrome-512x512.png"
            alt="Core"
          />
        </Link>
        <div className={styles.menu}>
          {navigation
            .sort((a, b) => (a.id > b.id ? 1 : -1))
            .map((x, index) =>
              x.url ? (
                <NavLink
                  className={({ isActive }) =>
                    isActive ? styles.isactive : styles.item
                  }
                  to={x.url}
                  key={index}
                  onClick={onClose}
                >
                  <Icon name={x.icon} size="24" />
                  {x.title}
                </NavLink>
              ) : (
                <Dropdown
                  className={styles.dropdown}
                  visibleSidebar={visible}
                  setValue={setVisible}
                  key={index}
                  item={x}
                  onClose={onClose}
                />
              )
            )}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>

      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
