import { paxios } from "../utils/axios";

export const status = async () => {
  const url = "/user/status";
  try {
    let result = await paxios.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};
