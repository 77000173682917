import React from "react";
import { CirclePicker } from "react-color";
import styles from "./Info.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TextInput from "../../../../components/TextInput";
import { colorsNap, renderSwitchColorNap } from "../../../../const/ConstMapa";

const InfoCrearNap = ({
  drawCreatedFormNap,
  setDrawCreatedFormNap,
  initialFormNap,
  onClose,
}) => {
  // Para formulario Nap
  const onChangeHandlerInput = (e) => {
    const { name, value } = e.target;
    setDrawCreatedFormNap((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    initialFormNap[name] = value;
  };

  // Para color NAP_I
  const onChangeHandlerColorNapI = (color) => {
    setDrawCreatedFormNap((prevState) => ({
      ...prevState,
      napColorHilo: renderSwitchColorNap(color.hex),
      napColorHilohex: color.hex,
    }));
    initialFormNap.napColorHilo = renderSwitchColorNap(color.hex);
    initialFormNap.napColorHilohex = color.hex;
  };

  // Para color NAP_II
  const onChangeHandlerColorNapII = (color) => {
    setDrawCreatedFormNap((prevState) => ({
      ...prevState,
      napColorBuffer: renderSwitchColorNap(color.hex),
      napColorBufferhex: color.hex,
    }));
    initialFormNap.napColorBuffer = renderSwitchColorNap(color.hex);
    initialFormNap.napColorBufferhex = color.hex;
  };

  return (
    <div className={styles.success}>
      <h2>NAP</h2>
      <br />
      <div className={styles.row}>
        <div className={styles.col}>
          <TextInput
            className={styles.field}
            label="Número de Hilo"
            name="napNumeroHilo"
            type="number"
            placeholder="Número de Hilo"
            tooltip="Más de 1 caracter"
            required
            min={0}
            onChange={onChangeHandlerInput}
            value={drawCreatedFormNap.napNumeroHilo}
          />
          <h4 className={styles.field}>Color de Hilo</h4>
          <div className={styles.field}>
            <CirclePicker
              color={drawCreatedFormNap.napColorHilohex}
              onChange={(e) => onChangeHandlerColorNapI(e)}
              circleSpacing={7}
              width={"100%"}
              styles={{ maxWidth: "500px" }}
              colors={colorsNap}
            />
          </div>
        </div>
        <div className={styles.col}>
          <TextInput
            className={styles.field}
            label="Número de Buffer"
            name="napNumeroBuffer"
            type="number"
            placeholder="Número de Buffer"
            tooltip="Más de 1 caracter"
            required
            min={0}
            onChange={onChangeHandlerInput}
            value={drawCreatedFormNap.napNumeroBuffer}
          />
          <h4 className={styles.field}>Color de Buffer</h4>
          <div className={styles.field}>
            <CirclePicker
              color={drawCreatedFormNap.napColorBufferhex}
              onChange={(e) => onChangeHandlerColorNapII(e)}
              circleSpacing={7}
              width={"100%"}
              styles={{ maxWidth: "500px" }}
              colors={colorsNap}
            />
          </div>
        </div>
      </div>
      <TextInput
        className={styles.field}
        label="Número de Puertos"
        name="napNumeroPuertos"
        type="number"
        placeholder="Número de Puertos"
        tooltip="Más de 1 caracter"
        required
        min={0}
        onChange={onChangeHandlerInput}
        value={drawCreatedFormNap.napNumeroPuertos}
      />
      <div className={styles.btns}>
        <button className={cn("button", styles.button)} onClick={onClose}>
          Continuar
        </button>
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default InfoCrearNap;
