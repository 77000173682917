import React from "react";
import { CirclePicker } from "react-color";
import styles from "./Info.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TextInput from "../../../../components/TextInput";
import ReactDOMServer from "react-dom/server";

import {
  renderSwitchIconsLeaflet,
  renderSwitchIcons,
  renderSwitchColor,
  customReactPopupMarker,
  nameIcons,
  nameIcons2,
  colors,
  colorsNap,
  renderSwitchColorNap,
  openModalNap,
  customReactTooltipMarker,
} from "../../../../const/ConstMapa";

const InfoEditMarker = ({
  drawEditForm,
  setDrawEditForm,
  layerEdit,
  onClose,
  editMarker,
  setEditMarker,
  getMarkers,
  setGetMarkers,
}) => {
  const onHandlerEditForm = () => {
    onClose();

    openModalNap.includes(editMarker.iconName)
      ? onChangeEditNap()
      : onChangeEditForm();
  };

  const onChangeEditForm = () => {
    // const markerIndex = editMarker.id;

    const newMarkerArr = [...getMarkers];
    // reset id so we can prevent duplicate markers(prevent multiple marker with same id)
    newMarkerArr.forEach((marker, index) => marker.id = index);
    const markerIndex = newMarkerArr.findIndex((e) =>  e?.id === editMarker.id);
    if(markerIndex === -1) return;

    newMarkerArr[markerIndex] = {
      ...newMarkerArr[markerIndex],
      ...editMarker,
    };

    setGetMarkers(newMarkerArr);
  };

  const onChangeEditNap = () => {
    const newMarkerArr = [...getMarkers];

    // reset id so we can prevent duplicate markers(prevent multiple marker with same id)
    newMarkerArr.forEach((marker, index) => marker.id = index);

    const markerIndex = newMarkerArr.findIndex((e) =>  e?.id === editMarker.id);

    if(markerIndex === -1) return;

    newMarkerArr[markerIndex] = {
      ...newMarkerArr[markerIndex],
      ...editMarker,
    };

    setGetMarkers(newMarkerArr);
  };

  // Para formulario
  const onChangeHandlerInput = (e) => {
    const { name, value } = e.target;

    setEditMarker((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Para icon
  const handlerSelectMarker = (value) => {
    let markerIcon = renderSwitchIconsLeaflet(value);

    let categoryType = "";

    if (value == "FcWorkflow") {
      categoryType = "Manga";
    } else if (value === "FcTimeline") {
      categoryType = "Reserva";
    } else if (value === "FcFilingCabinet") {
      categoryType = "Nap_I";
    } else if (value === "GrServers") {
      categoryType = "Nap_II";
    } else if (value === "FcReadingEbook") {
      categoryType = "Ont";
    } else {
      categoryType = "Marcadores";
    }

    setEditMarker((prevState) => ({
      ...prevState,
      iconMarker: markerIcon,
      iconName: value,
      categoryType: categoryType,
    }));
  };

  // Para color titulo
  const onChangeHandlerColorTitulo = (color) => {
    setEditMarker((prevState) => ({
      ...prevState,
      colorTitulo: renderSwitchColor(color.hex),
      colorTituloHex: color.hex,
    }));
  };

  // Para color NAP_I
  const onChangeHandlerColorNapI = (color) => {
    setEditMarker((prevState) => ({
      ...prevState,
      napColorHilo: renderSwitchColorNap(color.hex),
      napColorHilohex: color.hex,
    }));
  };

  // Para color NAP_II
  const onChangeHandlerColorNapII = (color) => {
    setEditMarker((prevState) => ({
      ...prevState,
      napColorBuffer: renderSwitchColorNap(color.hex),
      napColorBufferhex: color.hex,
    }));
  };

  return (
    <div className={styles.success}>
      <h2>Editar</h2>
      <br />
      <div className={styles.row}>
        <div className={styles.col}>
          <TextInput
            className={styles.field}
            label="Título Marcador"
            name="titulo"
            type="text"
            placeholder="Título Marcador"
            tooltip="Más de 2 caracteres"
            required
            maxLength="20"
            onChange={onChangeHandlerInput}
            value={editMarker.titulo}
          />
          <div className={styles.subcol}>
            <TextInput
              className={styles.field}
              label="Latitud"
              type="text"
              placeholder="Latitud"
              tooltip="Más de 2 caracteres"
              required
              maxLength="20"
              onChange={(e) => {
                let Coord = {
                  lat: Number(e.target.value),
                  lng: editMarker.Coords.lng,
                };

                setEditMarker((prevState) => ({
                  ...prevState,
                  Coords: Coord,
                }));
              }}
              value={editMarker.Coords.lat}
            />
            <TextInput
              className={styles.field}
              label="Longitud"
              type="text"
              placeholder="Longitud"
              tooltip="Más de 2 caracteres"
              required
              maxLength="20"
              onChange={(e) => {
                let Coords = {
                  lng: Number(e.target.value),
                  lat: editMarker.Coords.lat,
                };
                setEditMarker((prevState) => ({
                  ...prevState,
                  Coords: Coords,
                }));
              }}
              value={editMarker.Coords.lng}
            />
          </div>
          <TextInput
            className={styles.field}
            label="Descripción Marcador"
            name="descripcion"
            type="text"
            placeholder="Descripción Marcador"
            tooltip="Más de 2 caracteres"
            required
            onChange={onChangeHandlerInput}
            value={editMarker.descripcion}
          />
          <h4 className={styles.field}>Color de Título</h4>
          <div className={styles.field}>
            <CirclePicker
              color={drawEditForm.colorTituloHex}
              onChange={(e) => onChangeHandlerColorTitulo(e)}
              circleSpacing={7}
              width={"100%"}
              styles={{ maxWidth: "500px" }}
              colors={colors}
            />
          </div>
          <h4 className={styles.field}>Iconos</h4>
          <div className={styles.field}>
            {nameIcons.map((item, index) => {
              return (
                <button
                  className={cn(
                    "button-square-stroke button-small",
                    styles.iconbutton,
                    editMarker?.iconName?.includes(item)
                      ? styles.selectbutton
                      : null
                  )}
                  onClick={() => handlerSelectMarker(item)}
                  key={index}
                >
                  <img src={renderSwitchIcons(item)} height={"26"} alt={item} />
                </button>
              );
            })}
          </div>
          <div className={styles.iconcol}>
            {nameIcons2.map((item, index) => {
              return (
                <div className={styles.iconsubcol} key={index}>
                  <h4 className={styles.field}>{item.name}</h4>
                  <button
                    className={cn(
                      "button-square-stroke button-small",
                      styles.iconbutton,
                      editMarker?.iconName?.includes(item.icon)
                        ? styles.selectbutton
                        : null
                    )}
                    onClick={() => handlerSelectMarker(item.icon)}
                  >
                    <img
                      src={renderSwitchIcons(item.icon)}
                      height={"26"}
                      alt={item}
                    />
                  </button>
                </div>
              );
            })}
          </div>
          {/* SNAP */}
          {openModalNap.includes(editMarker.iconName) && (
            <>
              <br />
              <div className={styles.row}>
                <div className={styles.colnap}>
                  <TextInput
                    className={styles.field}
                    label="Número de Hilo"
                    name="napNumeroHilo"
                    type="number"
                    placeholder="Número de Hilo"
                    tooltip="Más de 1 caracter"
                    required
                    min={0}
                    onChange={onChangeHandlerInput}
                    value={editMarker.napNumeroHilo}
                  />
                  <h4 className={styles.field}>Color de Hilo</h4>
                  <div className={styles.field}>
                    <CirclePicker
                      color={drawEditForm.napColorHilohex}
                      onChange={(e) => onChangeHandlerColorNapI(e)}
                      circleSpacing={7}
                      width={"100%"}
                      styles={{ maxWidth: "500px" }}
                      colors={colorsNap}
                    />
                  </div>
                </div>
                <div className={styles.col}>
                  <TextInput
                    className={styles.field}
                    label="Número de Buffer"
                    name="napNumeroBuffer"
                    type="number"
                    placeholder="Número de Buffer"
                    tooltip="Más de 1 caracter"
                    required
                    min={0}
                    onChange={onChangeHandlerInput}
                    value={editMarker.napNumeroBuffer}
                  />
                  <h4 className={styles.field}>Color de Buffer</h4>
                  <div className={styles.field}>
                    <CirclePicker
                      color={drawEditForm.napColorBufferhex}
                      onChange={(e) => onChangeHandlerColorNapII(e)}
                      circleSpacing={7}
                      width={"100%"}
                      styles={{ maxWidth: "500px" }}
                      colors={colorsNap}
                    />
                  </div>
                </div>
              </div>
              <TextInput
                className={styles.field}
                label="Número de Puertos"
                name="napNumeroPuertos"
                type="number"
                placeholder="Número de Puertos"
                tooltip="Más de 1 caracter"
                required
                min={0}
                onChange={onChangeHandlerInput}
                value={editMarker.napNumeroPuertos}
              />
            </>
          )}

          {/*  */}

          <div
            className={styles.btns}
            style={{
              display: "flex",
              gap: "0.5rem",
              justifyContent: "flex-end",
            }}
          >
            <button
              className={cn("button", styles.button)}
              onClick={onHandlerEditForm}
            >
              Editar
            </button>

            <button
              className={cn("button", styles.button)}
              style={{
                background: "red",
              }}
              onClick={() => {
                const markerIndex = editMarker.id;

                let deleteMarker = getMarkers.filter(
                  (item) => item?.id !== markerIndex
                );

                setGetMarkers(deleteMarker);
                onClose();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default InfoEditMarker;
