import L from "leaflet";

// ICONS
import HiLocationMarker from "../../assets/icons/HiLocationMarker.png";
import FcAbout from "../../assets/icons/FcAbout.png";
import FcApproval from "../../assets/icons/FcApproval.png";
import FcHome from "../../assets/icons/FcHome.png";
import FcAcceptDatabase from "../../assets/icons/FcAcceptDatabase.png";
import FcAddDatabase from "../../assets/icons/FcAddDatabase.png";
import FcAddColumn from "../../assets/icons/FcAddColumn.png";
import FcBrokenLink from "../../assets/icons/FcBrokenLink.png";
import FcConferenceCall from "../../assets/icons/FcConferenceCall.png";
import FcDisclaimer from "../../assets/icons/FcDisclaimer.png";
import FcSupport from "../../assets/icons/FcSupport.png";
import FcVlc from "../../assets/icons/FcVlc.png";
import FcOk from "../../assets/icons/FcOk.png";
import FcRating from "../../assets/icons/FcRating.png";
import FcWorkflow from "../../assets/icons/FcWorkflow.png";
import FcTimeline from "../../assets/icons/FcTimeline.png";
import FcFilingCabinet from "../../assets/icons/FcFilingCabinet.png";
import GrServers from "../../assets/icons/GrServers.png";
import FcReadingEbook from "../../assets/icons/FcReadingEbook.png";
import Question from "../../assets/icons/Question.png";

// // Icon Marker
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

//Buttons
L.drawLocal.draw.toolbar.buttons.polyline = "Ruta";
L.drawLocal.draw.toolbar.buttons.marker = "Marcador";
L.drawLocal.draw.toolbar.actions.title = "Cancelar dibujo";
L.drawLocal.draw.toolbar.actions.text = "Cancelar ";
L.drawLocal.draw.toolbar.finish.title = "Terminar de dibujar";
L.drawLocal.draw.toolbar.finish.text = "Terminar";
L.drawLocal.draw.toolbar.undo.title = "Eliminar el último punto dibujado";
L.drawLocal.draw.toolbar.undo.text = "Eliminar el último punto";
L.drawLocal.edit.toolbar.actions.save.title = "Terminar de dibujar";
L.drawLocal.edit.toolbar.actions.save.text = "Terminar";
L.drawLocal.edit.toolbar.actions.cancel.title = "Cancelar dibujo";
L.drawLocal.edit.toolbar.actions.cancel.text = "Cancelar";
L.drawLocal.edit.toolbar.actions.clearAll.title = "Limpiar dibujo";
L.drawLocal.edit.toolbar.actions.clearAll.text = "Limpiar";
L.drawLocal.edit.toolbar.buttons.edit = "Editar";
L.drawLocal.edit.toolbar.buttons.editDisabled = "Sin capas para editar";
L.drawLocal.edit.toolbar.buttons.remove = "Eliminar";
L.drawLocal.edit.toolbar.buttons.removeDisabled = "Sin capas para eliminar";

//Tooltip
L.drawLocal.draw.handlers.polyline.tooltip.start =
  "Haga clic para comenzar a dibujar la línea.";
L.drawLocal.draw.handlers.polyline.tooltip.cont =
  "Haga clic para continuar dibujando la línea.";
L.drawLocal.draw.handlers.polyline.tooltip.end =
  "Haga clic en el último punto para terminar la línea.";
L.drawLocal.draw.handlers.marker.tooltip.start =
  "Haga clic para Colocar el Marcador.";
L.drawLocal.edit.handlers.edit.tooltip.text =
  "Arrastre los controladores o el marcador para editar entidades.";
L.drawLocal.edit.handlers.edit.tooltip.subtext =
  "Haga clic en cancelar para deshacer los cambios.";
L.drawLocal.edit.handlers.remove.tooltip.text =
  "Haga clic en una función para eliminar.";

// Icon Leaflet
const HiLocationMarkerIcon = new L.Icon({
  iconUrl: HiLocationMarker,
  iconSize: [30, 30],
});
const FcAboutIcon = new L.Icon({
  iconUrl: FcAbout,
  iconSize: [30, 30],
});
const FcApprovalIcon = new L.Icon({
  iconUrl: FcApproval,
  iconSize: [30, 30],
});
const FcHomeIcon = new L.Icon({
  iconUrl: FcHome,
  iconSize: [30, 30],
});
const FcAcceptDatabaseIcon = new L.Icon({
  iconUrl: FcAcceptDatabase,
  iconSize: [30, 30],
});
const FcAddDatabaseIcon = new L.Icon({
  iconUrl: FcAddDatabase,
  iconSize: [30, 30],
});
const FcAddColumnIcon = new L.Icon({
  iconUrl: FcAddColumn,
  iconSize: [30, 30],
});
const FcBrokenLinkIcon = new L.Icon({
  iconUrl: FcBrokenLink,
  iconSize: [30, 30],
});
const FcConferenceCallIcon = new L.Icon({
  iconUrl: FcConferenceCall,
  iconSize: [30, 30],
});
const FcDisclaimerIcon = new L.Icon({
  iconUrl: FcDisclaimer,
  iconSize: [30, 30],
});
const FcSupportIcon = new L.Icon({
  iconUrl: FcSupport,
  iconSize: [30, 30],
});
const FcVlcIcon = new L.Icon({
  iconUrl: FcVlc,
  iconSize: [30, 30],
});
const FcOkIcon = new L.Icon({
  iconUrl: FcOk,
  iconSize: [30, 30],
});
const FcRatingIcon = new L.Icon({
  iconUrl: FcRating,
  iconSize: [30, 30],
});
const FcWorkflowIcon = new L.Icon({
  iconUrl: FcWorkflow,
  iconSize: [30, 50],
});
const FcTimelineIcon = new L.Icon({
  iconUrl: FcTimeline,
  iconSize: [30, 30],
});
const FcFilingCabinetIcon = new L.Icon({
  iconUrl: FcFilingCabinet,
  iconSize: [30, 50],
});
const GrServersIcon = new L.Icon({
  iconUrl: GrServers,
  iconSize: [35, 30],
});
const FcReadingEbookIcon = new L.Icon({
  iconUrl: FcReadingEbook,
  iconSize: [30, 30],
});
const QuestionIcon = new L.Icon({
  iconUrl: Question,
  iconSize: [30, 30],
});

// Const
const nameIcons = [
  "HiLocationMarker",
  "FcAbout",
  "FcApproval",
  "FcHome",
  "FcAcceptDatabase",
  "FcAddDatabase",
  "FcAddColumn",
  "FcBrokenLink",
  "FcConferenceCall",
  "FcDisclaimer",
  "FcSupport",
  "FcVlc",
  "FcOk",
  "FcRating",
];

const nameIcons2 = [
  { name: "Manga", icon: "FcWorkflow" },
  { name: "Reserva", icon: "FcTimeline" },
  { name: "NAP_I", icon: "FcFilingCabinet" },
  { name: "NAP_II", icon: "GrServers" },
  { name: "ONT", icon: "FcReadingEbook" },
];

const openModalNap = ["FcFilingCabinet", "GrServers"];

// Para draw
const colors = [
  "#f44336",
  "#9c27b0",
  "#3f51b5",
  "#03a9f4",
  "#009688",
  "#cddc39",
  "#ff5722",
  "#795548",
];

// Para draw Nap
const colorsNap = [
  "#87CEFA",
  "#FFA500",
  "#228B22",
  "#8B4513",
  "#7f7f7f",
  "#cccccc",
  "#FF0000",
  "#000000",
  "#FFFF00",
  "#4B0082",
  "#FFC0CB",
  "#00FFFF",
];
// Switch
const renderSwitchIcons = (param) => {
  switch (param) {
    case "HiLocationMarker":
      return HiLocationMarker;
    case "FcAbout":
      return FcAbout;
    case "FcApproval":
      return FcApproval;
    case "FcHome":
      return FcHome;
    case "FcAcceptDatabase":
      return FcAcceptDatabase;
    case "FcAddDatabase":
      return FcAddDatabase;
    case "FcAddColumn":
      return FcAddColumn;
    case "FcBrokenLink":
      return FcBrokenLink;
    case "FcConferenceCall":
      return FcConferenceCall;
    case "FcDisclaimer":
      return FcDisclaimer;
    case "FcSupport":
      return FcSupport;
    case "FcVlc":
      return FcVlc;
    case "FcOk":
      return FcOk;
    case "FcRating":
      return FcRating;
    case "FcWorkflow":
      return FcWorkflow;
    case "FcTimeline":
      return FcTimeline;
    case "FcFilingCabinet":
      return FcFilingCabinet;
    case "GrServers":
      return GrServers;
    case "FcReadingEbook":
      return FcReadingEbook;
    default:
      return Question;
  }
};

const renderSwitchIconsLeaflet = (param) => {
  switch (param) {
    case "HiLocationMarker":
      return HiLocationMarkerIcon;
    case "FcAbout":
      return FcAboutIcon;
    case "FcApproval":
      return FcApprovalIcon;
    case "FcHome":
      return FcHomeIcon;
    case "FcAcceptDatabase":
      return FcAcceptDatabaseIcon;
    case "FcAddDatabase":
      return FcAddDatabaseIcon;
    case "FcAddColumn":
      return FcAddColumnIcon;
    case "FcBrokenLink":
      return FcBrokenLinkIcon;
    case "FcConferenceCall":
      return FcConferenceCallIcon;
    case "FcDisclaimer":
      return FcDisclaimerIcon;
    case "FcSupport":
      return FcSupportIcon;
    case "FcVlc":
      return FcVlcIcon;
    case "FcOk":
      return FcOkIcon;
    case "FcRating":
      return FcRatingIcon;
    case "FcWorkflow":
      return FcWorkflowIcon;
    case "FcTimeline":
      return FcTimelineIcon;
    case "FcFilingCabinet":
      return FcFilingCabinetIcon;
    case "GrServers":
      return GrServersIcon;
    case "FcReadingEbook":
      return FcReadingEbookIcon;
    default:
      return QuestionIcon;
  }
};

// Para Form
const renderSwitchColor = (param) => {
  switch (param) {
    case "#f44336":
      return "red";
    case "#9c27b0":
      return "purple";
    case "#3f51b5":
      return "darkblue";
    case "#03a9f4":
      return "blue";
    case "#009688":
      return "green";
    case "#cddc39":
      return "yellow";
    case "#ff5722":
      return "orange";
    case "#795548":
      return "brown";
    default:
      return "default";
  }
};

const renderSwitchColorReverse = (param) => {
  switch (param) {
    case "red":
      return "#f44336";
    case "purple":
      return "#9c27b0";
    case "darkblue":
      return "#3f51b5";
    case "blue":
      return "#03a9f4";
    case "green":
      return "#009688";
    case "yellow":
      return "#cddc39";
    case "orange":
      return "#ff5722";
    case "brown":
      return "#795548";
    default:
      return "default";
  }
};

// Para NAP
const renderSwitchColorNap = (param) => {
  switch (param) {
    case "#87cefa":
      return "Azul";
    case "#ffa500":
      return "Naranja";
    case "#228b22":
      return "Verde";
    case "#8b4513":
      return "Cafe";
    case "#7f7f7f":
      return "Gris";
    case "#cccccc":
      return "Blanco";
    case "#ff0000":
      return "Rojo";
    case "#000000":
      return "Negro";
    case "#ffff00":
      return "Amarillo";
    case "#4b0082":
      return "Violeta";
    case "#ffc0cb":
      return "Rosa";
    case "#00ffff":
      return "Aqua";
    default:
      return "default";
  }
};

const renderSwitchColorNapReverse = (param) => {
  switch (param) {
    case "Azul":
      return "#87cefa";
    case "Naranja":
      return "#ffa500";
    case "Verde":
      return "#228b22";
    case "Cafe":
      return "#8b4513";
    case "Gris":
      return "#7f7f7f";
    case "Blanco":
      return "#cccccc";
    case "Rojo":
      return "#ff0000";
    case "Negro":
      return "#000000";
    case "Amarillo":
      return "#ffff00";
    case "Violeta":
      return "#4b0082";
    case "Rosa":
      return "#ffc0cb";
    case "Aqua":
      return "#00ffff";
    default:
      return "default";
  }
};

// CUSTOMREACTPOPUPMARKER
const customReactPopupMarker = (infoMarker) => {
  const {
    titulo,
    descripcion,
    Coords,
    iconMarker,
    numeroHilo,
    colorHilo,
    numeroBuffer,
    colorBuffer,
    numeroPuertos,
  } = infoMarker;
  return (
    <div>
      <div>
        <strong>Título: </strong>
        {titulo}
      </div>
      {descripcion ? (
        <>
          <br />
          <div>
            <strong>Descripción: </strong>
            {descripcion}
          </div>
        </>
      ) : null}
      <br />
      <div>
        <strong>Coordenadas </strong>
        <br />
        Latitud: {Coords.lat}
        <br />
        Longitud: {Coords.lng}
      </div>
      {openModalNap.includes(iconMarker) ? (
        <>
          <br />
          <div>
            <strong>Hilos</strong>
            <br />
            Color: {colorHilo ? colorHilo : "Color_Default"}
            <br />
            Número: {numeroHilo ? numeroHilo : 0}
          </div>
          <br />
          <div>
            <strong>Buffer</strong>
            <br />
            Color: {colorBuffer ? colorBuffer : "Color_Default"}
            <br />
            Número: {numeroBuffer ? numeroBuffer : 0}
          </div>
          <br />
          <div>
            <strong>Puertos: </strong>
            {numeroPuertos ? numeroPuertos : 0}
          </div>
        </>
      ) : null}
    </div>
  );
};

// CUSTOMREACTTOOLTIPMARKER
const customReactTooltipMarker = (infoMarker) => {
  const { titulo, messageDobleClick } = infoMarker;
  return (
    <>
      {titulo}
      {messageDobleClick ? (
        <>
          <br />
          <div>
            <p>
              <i>(Doble click para editar)</i>
            </p>
          </div>
        </>
      ) : null}
    </>
  );
};

// CUSTOMREACTPOPUPPOLYLINE
const customReactPopupPolyline = (infoPolyline) => {
  const { titulo, descripcion, totalDistance } = infoPolyline;
  return (
    <>
      <div>
        <strong>Título: </strong>
        {titulo}
      </div>
      {descripcion ? (
        <>
          <br />
          <div>
            <strong>Descripción: </strong>
            {descripcion}
          </div>
        </>
      ) : null}
      <br />
      <div>
        <strong>Longitud: </strong>
        {totalDistance} metros.
      </div>
      <br />
    </>
  );
};

// CUSTOMREACTTOOLTIPPOLYLINE
const customReactTooltipPolyline = (infoPolyline) => {
  const { titulo, totalDistance, messageDobleClick } = infoPolyline;
  return (
    <>
      {titulo}
      <br />
      <div>
        <strong>{totalDistance / 1000}Kms</strong>
      </div>
      {messageDobleClick ? (
        <>
          {/* <br /> */}
          <div>
            <p>
              <i>(Doble click para editar)</i>
            </p>
          </div>
        </>
      ) : null}
    </>
  );
};

export {
  renderSwitchIcons,
  renderSwitchIconsLeaflet,
  renderSwitchColor,
  renderSwitchColorReverse,
  renderSwitchColorNap,
  renderSwitchColorNapReverse,
  customReactPopupMarker,
  customReactTooltipMarker,
  customReactPopupPolyline,
  customReactTooltipPolyline,
  nameIcons,
  nameIcons2,
  colors,
  openModalNap,
  colorsNap,
};
