import React, { Suspense, useEffect, useState } from "react";
import "./styles/app.sass";
import styles from "./App.module.sass";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AOS from "aos";
//
import Page from "./components/Page";
import Loading from "./screens/Loading";
import { logout, reset } from "./features/auth/authSlice";
import { PrivateRoute } from "./utils";
import MapaEdit from "./screens/MapaEdit";
import ModificarPlan from "./screens/Planes/ModificarPlan";
import { codeInterceptor } from "./utils/axios";
// AXIOS
import { status } from "./axios";
//animation aos init
AOS.init();
///////////////////////////////////////
const Home = React.lazy(() => import("./screens/Home"));
const Ficha = React.lazy(() => import("./screens/Ficha"));
//
const SignUp = React.lazy(() => import("./screens/SignUp"));
const SignIn = React.lazy(() => import("./screens/SignIn"));
const Tester = React.lazy(() => import("./screens/Tester"));
const Mapa = React.lazy(() => import("./screens/Mapa"));
const CrearUsuario = React.lazy(() =>
  import("./screens/Usuarios/CrearUsuario")
);
const ListasUsuarios = React.lazy(() =>
  import("./screens/Usuarios/ListasUsuarios")
);
const ModificarUsuario = React.lazy(() =>
  import("./screens/Usuarios/ModificarUsuario")
);
const HistorialPagos = React.lazy(() => import("./screens/HistorialPagos"));
const CrearPlan = React.lazy(() => import("./screens/Planes/CrearPlan"));
const ListasPlanes = React.lazy(() => import("./screens/Planes/ListasPlanes"));
const Email = React.lazy(() => import("./screens/Email"));
const Backup = React.lazy(() => import("./screens/Backup"));
const BackupFicha = React.lazy(() => import("./screens/BackupFicha"));
const NotFound = React.lazy(() => import("./screens/NotFound"));

///////////////////////////////////////
const App = () => {
  const [isPending, setIsPending] = useState(false);

  const { isSuccess } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  useEffect(() => {
    codeInterceptor(() => {
      dispatch(logout());
      dispatch(reset());
    });
  }, [dispatch]);

  useEffect(() => {
    if (isSuccess) handlerLoad();
  }, [isSuccess]);

  const handlerLoad = async () => {
    let apiReturnStatus = await status();
    if (apiReturnStatus && apiReturnStatus.data === "PENDING")
      setIsPending(true);
  };
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          {/* PRIVATE ROUTES */}
          {isPending ? (
            <>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Page title="Listas de Planes" className={styles.container}>
                      <ListasPlanes />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/planes/list"
                element={
                  <PrivateRoute>
                    <Page title="Listas de Planes" className={styles.container}>
                      <ListasPlanes />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="*"
                element={
                  <Page title="404 NOT FOUND" className={styles.container}>
                    <NotFound />
                  </Page>
                }
              />
            </>
          ) : (
            <>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Page title="Dashboard" className={styles.container}>
                      <Home />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/fichas"
                element={
                  <PrivateRoute>
                    <Page title="Fichas">
                      <Ficha />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/mapa"
                element={
                  <PrivateRoute>
                    <Page title="Mapa">
                      <Mapa />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/mapa/:id"
                element={
                  <PrivateRoute>
                    <Page title="Editar Mapa">
                      <MapaEdit />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/user/crear"
                element={
                  <PrivateRoute>
                    <Page title="Crear Usuario" className={styles.container}>
                      <CrearUsuario />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/user/list"
                element={
                  <PrivateRoute>
                    <Page title="Listas de Usuarios">
                      <ListasUsuarios />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/user/:id"
                element={
                  <PrivateRoute>
                    <Page
                      title="Modificar Usuario"
                      className={styles.container}
                    >
                      <ModificarUsuario />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="admin/historial-pagos"
                element={
                  <PrivateRoute>
                    <Page title="Historial de Pagos">
                      <HistorialPagos />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/planes/crear"
                element={
                  <PrivateRoute>
                    <Page title="Crear Plan" className={styles.container}>
                      <CrearPlan />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/planes/list"
                element={
                  <PrivateRoute>
                    <Page title="Listas de Planes" className={styles.container}>
                      <ListasPlanes />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/planes/:id"
                element={
                  <PrivateRoute>
                    <Page title="Modificar Plan" className={styles.container}>
                      <ModificarPlan />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/email"
                element={
                  <PrivateRoute>
                    <Page title="Correo" className={styles.container}>
                      <Email />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/backup"
                element={
                  <PrivateRoute>
                    <Page title="Backup" className={styles.container}>
                      <Backup />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="/admin/backup/:id"
                element={
                  <PrivateRoute>
                    <Page title="Fichas de Backup">
                      <BackupFicha />
                    </Page>
                  </PrivateRoute>
                }
                exact
              />
              <Route
                path="*"
                element={
                  <Page title="404 NOT FOUND" className={styles.container}>
                    <NotFound />
                  </Page>
                }
              />

              {/* /// */}
              <Route
                path="/test"
                element={
                  <Page title="test" className={styles.container}>
                    <Tester />
                  </Page>
                }
                exact
              />
            </>
          )}

          {/* PUBLIC ROUTES */}
          <Route path="/registro" element={<SignUp />} exact />
          <Route path="/login" element={<SignIn />} exact />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
