import React from "react";
import styles from "./Info.module.sass";
import cn from "classnames";

const InfoAlert = ({ modalData, onClose }) => {
  return (
    <div className={styles.success}>
      <div className={styles.icon}>
        <span role="img" aria-label={modalData.iconName}>
          {modalData.iconEmoji}
        </span>
      </div>
      <div className={styles.info}>{modalData.title}</div>
      <div className={cn("h2", styles.price)}>{modalData.subtitle}</div>
      <div className={styles.text}>
        <div dangerouslySetInnerHTML={{ __html: modalData.message }} />
      </div>
      <button className={cn("button-stroke", styles.button)} onClick={onClose}>
        {modalData.buttonCancel}
      </button>
    </div>
  );
};

export default InfoAlert;
