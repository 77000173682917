import React from "react";
import { CirclePicker } from "react-color";
import styles from "./Info.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../../components/TooltipGlodal";
import TextInput from "../../../../components/TextInput";
import ReactDOMServer from "react-dom/server";

import {
  renderSwitchColor,
  colors,
  customReactTooltipPolyline,
  customReactPopupPolyline,
} from "../../../../const/ConstMapa";

const InfoEditPolyline = ({
  drawEditForm,
  setDrawEditForm,
  layerEdit,
  onClose,
}) => {
  const onHandlerEditForm = () => {
    onClose();
    return (
      (layerEdit.target.myTag.titulo = drawEditForm.tituloRuta),
      (layerEdit.target.myTag.descripcion = drawEditForm.descripcionRuta),
      (layerEdit.target.myTag.colorPopup = drawEditForm.colorRutaPopup),
      (layerEdit.target.myTag.colorRuta = drawEditForm.colorRuta),
      layerEdit.target.setStyle({ color: drawEditForm.colorRuta }),
      layerEdit.target.setTooltipContent(
        ReactDOMServer.renderToString(
          customReactTooltipPolyline({
            titulo: drawEditForm.tituloRuta,
            totalDistance: drawEditForm.totalDistance,
            messageDobleClick: true,
          })
        )
      ),
      layerEdit.target
        .setPopupContent(
          ReactDOMServer.renderToString(
            customReactPopupPolyline({
              titulo: drawEditForm.tituloRuta,
              descripcion: drawEditForm.descripcionRuta,
              totalDistance: drawEditForm.totalDistance,
            })
          )
        )
        .openPopup()
    );
  };

  // Para formulario
  const onChangeHandlerInput = (e) => {
    const { name, value } = e.target;
    setDrawEditForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Para color ruta popup
  const onChangeHandlercolorRutaPopup = (color) => {
    setDrawEditForm((prevState) => ({
      ...prevState,
      colorRutaPopup: renderSwitchColor(color.hex),
      colorRutaPopupHex: color.hex,
    }));
  };

  return (
    <div className={styles.success}>
      <h2>Editar</h2>
      <br />
      <div className={styles.row}>
        <div className={styles.col}>
          <TextInput
            className={styles.field}
            label="Título Ruta"
            name="tituloRuta"
            type="text"
            placeholder="Título Ruta"
            tooltip="Más de 2 caracteres"
            required
            maxLength="20"
            onChange={onChangeHandlerInput}
            value={drawEditForm.tituloRuta}
          />
          <TextInput
            className={styles.field}
            label="Distancia total de ruta"
            name="totalDistance"
            type="text"
            placeholder="Distancia total de ruta"
            tooltip="Más de 2 caracteres"
            required
            maxLength="20"
            value={`${drawEditForm.totalDistance} metros`}
          />
          <TextInput
            className={styles.field}
            label="Descripción de Ruta"
            name="descripcionRuta"
            type="text"
            placeholder="Descripción de Ruta"
            tooltip="Más de 2 caracteres"
            required
            onChange={onChangeHandlerInput}
            value={drawEditForm.descripcionRuta}
          />
          <h4 className={styles.field}>Color de Popup</h4>
          <div className={styles.field}>
            <CirclePicker
              color={drawEditForm.colorRutaPopupHex}
              onChange={(e) => onChangeHandlercolorRutaPopup(e)}
              circleSpacing={7}
              width={"100%"}
              styles={{ maxWidth: "500px" }}
              colors={colors}
            />
          </div>
          <TextInput
            className={styles.field}
            label="Color de Ruta"
            name="colorRuta"
            type="color"
            onChange={onChangeHandlerInput}
            value={drawEditForm.colorRuta}
          />
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={onHandlerEditForm}
            >
              Editar
            </button>
          </div>
        </div>
      </div>
      <TooltipGlodal />
    </div>
  );
};

export default InfoEditPolyline;
