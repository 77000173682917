import { publicAxios, setJWT } from "../../utils/axios";

// Login user
const login = async (userData) => {
  const response = await publicAxios.post("user/login", userData);
  if (response.data) {
    const { statuslogin } = response.data;
    if (statuslogin === "VALID") {
      // delete response.data._id;

      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("jwt", JSON.stringify(response.data.jwt));
      setJWT(response.data.jwt || "");
    }
  }
  return response.data;
};

// Register user
const register = async (userData) => {
  const response = await publicAxios.post("user/registrar", userData);
  // if (response.data) {
  //   localStorage.setItem("user", JSON.stringify(response.data));
  // }
  return response.data;
};

// Logout user
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("jwt");
};

const authService = {
  register,
  logout,
  login,
};

export default authService;
