import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import Loader from "../Loader";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";

const TextInput = ({
  success,
  error,
  className,
  classLabel,
  classInput,
  label,
  icon,
  copy,
  password,
  currency,
  tooltip,
  place,
  type,
  ...props
}) => {
  const inputRef = useRef();
  const [isPasswordVisible, setisPasswordVisible] = useState(false);
  const [isType, setisType] = useState(type);

  useEffect(() => {}, [isType]);
  const copyHandler = () => {
    navigator.clipboard.writeText(props.value);
  };

  const passwordToggle = () => {
    inputRef.current.focus();
    setisType((prev) => {
      if (prev === "password") return "text";
      return "password";
    });
    setisPasswordVisible((prev) => !prev);
  };

  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldCurrency]: currency },
        { [styles.fieldPassword]: password },
        { [styles.fieldError]: error },
        { [styles.fieldSuccess]: success },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />
          </div>
        )}
        <input
          ref={inputRef}
          className={cn(classInput, styles.input)}
          type={isType}
          {...props}
        />

        {props.disabled ? (
          <div className={styles.copy}>
            <Loader className={styles.loader} />
          </div>
        ) : (
          <>
            {error && (
              <div className={styles.error}>
                <Icon name="close" size="24" />
              </div>
            )}
            {success && (
              <div className={styles.success}>
                <Icon name="check" size="24" />
              </div>
            )}
            {copy && (
              <button
                type="button"
                className={styles.copy}
                onClick={() => copyHandler()}
              >
                <Icon name="copy" size="24" />
              </button>
            )}
            {password && (
              <button
                type="button"
                className={styles.password}
                onClick={() => passwordToggle()}
              >
                <Icon
                  name={isPasswordVisible ? "toggle-on" : "toggle-off-stroke"}
                  size="24"
                />
              </button>
            )}
          </>
        )}
        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
    </div>
  );
};

export default TextInput;
