/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import ReactDOMServer from "react-dom/server";

import { EditControl } from "react-leaflet-draw";
import getDistance from "geolib/es/getDistance";

import L from "leaflet";
import "react-leaflet-draw";

import {
  renderSwitchIconsLeaflet,
  customReactPopupMarker,
  customReactPopupPolyline,
  customReactTooltipPolyline,
  customReactTooltipMarker,
  renderSwitchColorReverse,
  renderSwitchColorNapReverse,
} from "../../const/ConstMapa";
import { setDatasDrawsSave } from "../../const/ConstGlobal";
import { useMapEvents, useMap } from "react-leaflet";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

const MapHook = ({
  dataMarkers,
  dataPolylines,
  markerLeaflet,
  polylineLeaflet,
  initialMarkerLeaflet,
  initialForm,
  initialFormNap,
  setDrawEditForm,
  setLayerEdit,
  onEditVisibleModalMarker,
  onEditVisibleModalPolyline,
  onClose,
  getMarkers,
  setGetMarkers,
  addMark,
  showRoutes,
  bounds,
}) => {
  var activeModal = true;

  const [drawControl, setDrawControl] = useState({
    rectangle: false,
    circlemarker: false,
    polygon: false,
    circle: false,
    polyline: {},
    marker: false,
  });

  const map = useMap();

  const context = useLeafletContext();

  useEffect(() => {
    setDrawControl({
      ...drawControl,
      polyline: polylineLeaflet,
    });
  }, [markerLeaflet, polylineLeaflet]);
  const [addedLayer, setAddedLayer] = useState(null);
  
  const data = useMapEvents({
    click: (e) => {
      if (addMark) {
        var newMarker = {};
        newMarker.id = getMarkers.length;
        newMarker.type = "marker";
        newMarker.titulo = initialForm.tituloMarcador;
        newMarker.descripcion = initialForm.descripcionMarcador;
        newMarker.Coords = e.latlng;
        newMarker.colorTitulo = initialForm.colorTitulo;
        newMarker.iconMarker = initialMarkerLeaflet.icon;
        newMarker.numeroHilo = initialFormNap.napNumeroHilo;
        newMarker.colorHilo = initialFormNap.napColorHilo;
        newMarker.numeroBuffer = initialFormNap.napNumeroBuffer;
        newMarker.colorBuffer = initialFormNap.napColorBuffer;
        newMarker.numeroPuertos = initialFormNap.napNumeroPuertos;
        newMarker.categoryType = "Marcadores";
        newMarker.iconName = "HiLocationMarker";

        setGetMarkers((markerArr) => [...markerArr, newMarker]);
      }
    },
  });

  useEffect(() => {
    L.control
      .fullscreen({
        position: "topleft",
        title: "¡Mostrar la pantalla completa!",
        titleCancel: "Salir del modo de pantalla completa",
        content: null,
        forceSeparateButton: true,
        forcePseudoFullscreen: false,
        fullscreenElement: false,
      })
      .addTo(map);
  }, []);

  useEffect(() => {
    // dataMarkers &&
    // dataMarkers.map((marker) => {
    //   let newMarker = L.marker([marker.Coords.lat, marker.Coords.lng], {
    //     icon: renderSwitchIconsLeaflet(marker.iconMarker),
    //   })
    //     .bindPopup(
    //       ReactDOMServer.renderToString(
    //         customReactPopupMarker({
    //           titulo: marker.titulo,
    //           descripcion: marker.descripcion,
    //           Coords: marker.Coords,
    //           iconMarker: marker.iconMarker,
    //           numeroHilo: marker.numeroHilo,
    //           colorHilo: marker.colorHilo,
    //           numeroBuffer: marker.numeroBuffer,
    //           colorBuffer: marker.colorBuffer,
    //           numeroPuertos: marker.numeroPuertos,
    //         })
    //       )
    //     )
    //     .bindTooltip(
    //       ReactDOMServer.renderToString(
    //         customReactTooltipMarker({
    //           titulo: marker.titulo,
    //           messageDobleClick: true,
    //         })
    //       )
    //     )
    //     .on("dblclick", (e) => {
    //       activeModal ? onEditMarkerHandler(e) : onClose();
    //     })
    //     .addTo(context.layerContainer);

    //     console.log("maaaappppp")

    //   newMarker.myTag = marker;
    //   newMarker.myTag.type = "marker";
    // });

    if (dataPolylines.length == 0) {
      bounds && context.map.fitBounds(bounds);
    }

     
    if (dataPolylines.length > 0 && showRoutes) {
      let newArr = [];

      dataPolylines.map((polyline) => {
        let polylines = [];
        polyline.Coords.map((coord) => {
          polylines.push([coord.lat, coord.lng]);
        });

        let newPolyline = L.polyline([polylines], {
          color: polyline.colorRuta,
        })
          .bindPopup(
            ReactDOMServer.renderToString(customReactPopupPolyline(polyline))
          )
          .bindTooltip(
            ReactDOMServer.renderToString(
              customReactTooltipPolyline({
                titulo: polyline.titulo,
                totalDistance: polyline.totalDistance,
                messageDobleClick: true,
              })
            )
          )
          .on("dblclick", (e) => {
            activeModal ? onEditPolylineHandler(e) : onClose();
          })
          .addTo(context.layerContainer);

        newPolyline.myTag = polyline;
        newPolyline.myTag.type = "polyline";

        newArr.push(newPolyline);
        setAddedLayer(newArr);

        const getLayers = Object.values(context.layerContainer._layers);
        context.map.fitBounds(context.layerContainer.getBounds());
        // context.map.setZoom(19);
        setDatasDrawsSave(getLayers);
        return () => {
          context.map.removeLayer(context.layerContainer);
        };
        
      });
    }
  }, [dataPolylines, showRoutes]);

  useEffect(() => {
    
    if (!showRoutes && dataPolylines.length > 0) {
      map.eachLayer((layer) => {
        addedLayer.map((item) => {
          if (layer === item) {
            map.removeLayer(layer);
          }
        });
      });
    }
  }, [showRoutes, addedLayer]);

  const onCreated = (e) => {
    var layer = e.layer;
    if (e.layerType === "marker") {
      var newMarker = {};
      if (initialMarkerLeaflet.nameIcon === "FcFilingCabinet") {
        newMarker.type = e.layerType;
        newMarker.titulo = initialForm.tituloMarcador;
        newMarker.descripcion = initialForm.descripcionMarcador;
        newMarker.Coords = layer._latlng;
        newMarker.colorTitulo = initialForm.colorTitulo;
        newMarker.iconMarker = initialMarkerLeaflet.nameIcon;
        newMarker.numeroHilo = initialFormNap.napNumeroHilo;
        newMarker.colorHilo = initialFormNap.napColorHilo;
        newMarker.numeroBuffer = initialFormNap.napNumeroBuffer;
        newMarker.colorBuffer = initialFormNap.napColorBuffer;
        newMarker.numeroPuertos = initialFormNap.napNumeroPuertos;
      } else if (initialMarkerLeaflet.nameIcon === "GrServers") {
        newMarker.type = e.layerType;
        newMarker.titulo = initialForm.tituloMarcador;
        newMarker.descripcion = initialForm.descripcionMarcador;
        newMarker.Coords = layer._latlng;
        newMarker.colorTitulo = initialForm.colorTitulo;
        newMarker.iconMarker = initialMarkerLeaflet.nameIcon;
        newMarker.numeroHilo = initialFormNap.napNumeroHilo;
        newMarker.colorHilo = initialFormNap.napColorHilo;
        newMarker.numeroBuffer = initialFormNap.napNumeroBuffer;
        newMarker.colorBuffer = initialFormNap.napColorBuffer;
        newMarker.numeroPuertos = initialFormNap.napNumeroPuertos;
      } else {
        newMarker.type = e.layerType;
        newMarker.titulo = initialForm.tituloMarcador;
        newMarker.descripcion = initialForm.descripcionMarcador;
        newMarker.Coords = layer._latlng;
        newMarker.colorTitulo = initialForm.colorTitulo;
        newMarker.iconMarker = initialMarkerLeaflet.nameIcon;
      }
      layer.myTag = newMarker;
      layer
        .bindPopup(
          ReactDOMServer.renderToString(
            customReactPopupMarker({
              titulo: newMarker.titulo,
              descripcion: newMarker.descripcion,
              Coords: newMarker.Coords,
              iconMarker: newMarker.iconMarker,
              numeroHilo: newMarker.numeroHilo,
              colorHilo: newMarker.colorHilo,
              numeroBuffer: newMarker.numeroBuffer,
              colorBuffer: newMarker.colorBuffer,
              numeroPuertos: newMarker.numeroPuertos,
            })
          )
        )
        .bindTooltip(
          ReactDOMServer.renderToString(
            customReactTooltipMarker({
              titulo: newMarker.titulo,
              messageDobleClick: true,
            })
          )
        )
        .on("dblclick", (e) => {
          activeModal ? onEditMarkerHandler(e) : onClose();
        });
    }
    if (e.layerType === "polyline") {
      let countDistance = 0;
      for (let x = 0; x < e.layer._latlngs.length - 1; x++) {
        let count = getDistance(
          {
            latitude: e.layer._latlngs[x].lat,
            longitude: e.layer._latlngs[x].lng,
          },
          {
            latitude: e.layer._latlngs[x + 1].lat,
            longitude: e.layer._latlngs[x + 1].lng,
          }
        );
        countDistance += count;
      }

      var newPolyline = {};
      newPolyline.type = e.layerType;
      newPolyline.titulo = initialForm.tituloRuta;
      newPolyline.descripcion = initialForm.descripcionRuta;
      newPolyline.Coords = e.layer._latlngs;
      newPolyline.colorPopup = initialForm.colorRutaPopup;
      newPolyline.colorRuta = initialForm.colorRuta;
      newPolyline.totalDistance = countDistance;
      layer.myTag = newPolyline;

      layer
        .bindPopup(
          ReactDOMServer.renderToString(customReactPopupPolyline(newPolyline))
        )
        .bindTooltip(
          ReactDOMServer.renderToString(
            customReactTooltipPolyline({
              titulo: newPolyline.titulo,
              totalDistance: newPolyline.totalDistance,
              messageDobleClick: true,
            })
          )
        )
        .on("dblclick", (e) => {
          activeModal ? onEditPolylineHandler(e) : onClose();
        });
    }
    const getLayers = Object.values(context.layerContainer._layers);
    setDatasDrawsSave(getLayers);
  };

  const onDeleted = (e) => {
    activeModal = true;

    const getLayers = Object.values(context.layerContainer._layers);
    setDatasDrawsSave(getLayers);
  };

  const onEdited = (e) => {
    activeModal = true;
    var layersEdits = Object.values(e.layers._layers);
    layersEdits.map((layerEdit) => {
      if (layerEdit.myTag.type === "marker") {
        return (
          (layerEdit.myTag.Coords = layerEdit._latlng),
          layerEdit.setPopupContent(
            ReactDOMServer.renderToString(
              customReactPopupMarker({
                titulo: layerEdit.myTag.titulo,
                descripcion: layerEdit.myTag.descripcion,
                Coords: layerEdit._latlng,
              })
            )
          )
        );
      }
      if (layerEdit.myTag.type === "polyline") {
        let countDistanceEdit = 0;
        let _latlngs =
          layerEdit._latlngs.length === 1
            ? layerEdit._latlngs[0]
            : layerEdit._latlngs;

        for (let x = 0; x < _latlngs.length - 1; x++) {
          let count = getDistance(
            {
              latitude: _latlngs[x].lat,
              longitude: _latlngs[x].lng,
            },
            {
              latitude: _latlngs[x + 1].lat,
              longitude: _latlngs[x + 1].lng,
            }
          );
          countDistanceEdit += count;
        }
        return (
          (layerEdit.myTag.totalDistance = countDistanceEdit),
          (layerEdit.myTag.Coords = _latlngs),
          layerEdit.setTooltipContent(
            ReactDOMServer.renderToString(
              customReactTooltipPolyline({
                titulo: layerEdit.myTag.titulo,
                totalDistance: countDistanceEdit,
                messageDobleClick: true,
              })
            )
          ),
          layerEdit.setPopupContent(
            ReactDOMServer.renderToString(
              customReactPopupPolyline({
                titulo: layerEdit.myTag.titulo,
                descripcion: layerEdit.myTag.descripcion,
                totalDistance: countDistanceEdit,
              })
            )
          )
        );
      }
    });

    const getLayers = Object.values(context.layerContainer._layers);
    setDatasDrawsSave(getLayers);
  };

  const onEditMarkerHandler = (e) => {
    const { myTag } = e.target;
    setDrawEditForm((prevState) => ({
      ...prevState,
      tituloMarcador: myTag.titulo,
      descripcionMarcador: myTag.descripcion,
      colorTitulo: myTag.colorTitulo,
      colorTituloHex: renderSwitchColorReverse(myTag.colorTitulo),
      coords: myTag.Coords,
      type: myTag.type,
      iconMarker: myTag.iconMarker,
      napNumeroHilo: myTag.numeroHilo ? myTag.numeroHilo : 0,
      napColorHilo: myTag.colorHilo ? myTag.colorHilo : "",
      napColorHilohex: myTag.colorHilo
        ? renderSwitchColorNapReverse(myTag.colorHilo)
        : "",
      napNumeroBuffer: myTag.numeroBuffer ? myTag.numeroBuffer : 0,
      napColorBuffer: myTag.colorBuffer ? myTag.colorBuffer : "",
      napColorBufferhex: myTag.colorBuffer
        ? renderSwitchColorNapReverse(myTag.colorBuffer)
        : "",
      napNumeroPuertos: myTag.numeroPuertos ? myTag.numeroPuertos : 0,
    }));
    onEditVisibleModalMarker();
    setLayerEdit(e);
  };

  const onEditPolylineHandler = (e) => {
    const { myTag } = e.target;
    setDrawEditForm((prevState) => ({
      ...prevState,
      tituloRuta: myTag.titulo,
      descripcionRuta: myTag.descripcion,
      colorRutaPopup: myTag.colorPopup,
      colorRutaPopupHex: renderSwitchColorReverse(myTag.colorPopup),
      colorRuta: myTag.colorRuta,
      coords: myTag.Coords,
      totalDistance: myTag.totalDistance,
      type: myTag.type,
    }));
    onEditVisibleModalPolyline();
    setLayerEdit(e);
  };

  return (
    <EditControl
      position="topright"
      onCreated={(e) => onCreated(e)}
      onEdited={(e) => onEdited(e)}
      onDeleteStart={(e) => (activeModal = false)}
      onDeleteStop={(e) => (activeModal = true)}
      onEditStart={(e) => (activeModal = false)}
      onEditStop={(e) => (activeModal = true)}
      onDeleted={(e) => onDeleted(e)}
      draw={drawControl}
    />
  );
};

export default MapHook;
